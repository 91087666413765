<template>
  <div>
    <v-container>
      <page-title title="Campaign"></page-title>

      <v-row>
        <v-col>
          <v-card>
            <v-toolbar dense elevation="0">
              <v-toolbar-title>List Data</v-toolbar-title>
            </v-toolbar>
            <list-data></list-data>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ListData from './components/ListData.vue';
export default {
  components: {
    ListData,
  },
  data() {
    return {
      loading: null,
      loading2: null,
      dialog: null,
      breadcrumbItems: [
        {
          text: 'Dashboard',
          disabled: true,
          to: { name: "Campaign" },
        },
      ],
      search: "",
      datatable: {
        loading: false,
        loadingText: "Loading... Please wait...",
        columns: [
          { text: "No.", value: "no_" },
          { text: "Description", value: "description" },
          { text: "Area", value: "area" },
          { text: "Status Code", value: "status_code" },
          { text: "Status", value: "status" },
          { text: "Last Sync", value: "last_sync_date" },
        ],
        data: [],
      },
      selectedCutomer: '',

      customers: [],
      customersListItems: [],

      selectedCampaignNo: '',
      selectedCutomerNo: '',
    }
  },

  methods: {
    getData(refresh) {
      if(refresh) this.AxiosStorageRemove('GET', 'campaign/data')
      this.datatable.loading = true;

      var params = [];
      this.$axios
        .get("campaign/data", {
          params: params,
          cacheConfig: true,
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data;
          }else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },

    getDetail(){
      console.log(this.selectedCutomer)
      this.redirect('Sales.Campaign.Detail', { no_ : this.selectedCampaignNo, customer_no_: this.selectedCutomerNo} );
    },

    getCustomers(campaign_no_){

      this.selectedCampaignNo = campaign_no_

      this.dialog = true
      this.selectedCutomer = {}
      this.customers = []
      this.customersListItems = []

      this.loading2 = true;
      var params = {
        campaign_no_: this.selectedCampaignNo,
      };
      this.$axios
        .get("campaign/customer-list", {
          params: params,
        })
        .then((res) => {
          this.loading2 = false;
          var resData = res.data;
          if (resData.status == "success") {
            this.customers = resData.data;

            if(this.customers.length == 1){
              this.selectedCutomer = this.customers[0]
            }

            for(var i = 0; i<this.customers.length;i++){
              this.customersListItems.push({
                text: this.customers[i].name + ' ('+ this.customers[i].no_ +')',
                value: this.customers[i].no_
              });
            }
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        .catch((error) => {
          this.loading2 = false;
          this.axiosErrorHandler(error);
        });
    }
  },

  mounted() {
    this.requiredLogin();
    // this.getData();
  },
};
</script>